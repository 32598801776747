import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import TransactionTable from "./TransactionTable";
import styles from "./BankDataComponent.module.css";
import config from "../../config/config";
import Spinner from "../common/Spinner";

const BankAccountsData = ({
  setAccountsID,
  setIBAN,
  setExpandUrls,
  setMayGetExtraUrlForEachAccountNumberForExcel,
}) => {
  const navigate = useNavigate();

  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [expandedInstitution, setExpandedInstitution] = useState(null);
  const [expandedIBAN, setExpandedIBAN] = useState(null);
  const [expandedCurrency, setExpandedCurrency] = useState(null);
  const [transactions, setTransactions] = useState({});
  const [searchTransaction, setSearchTransaction] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [excelInstructions, setExcelInstructions] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [message, setMessage] = useState(null);
  const [messagePositive, setMessagePositive] = useState(null);
  const [expandedTransaction, setExpandedTransaction] = useState(null);
  const [sortOrder, setSortOrder] = useState("desc"); // Default to descending order

  const containerRef = useRef(null);

  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setIsAtStart(false); // Always keep the left arrow active
      setIsAtEnd(false); // Always keep the right arrow active
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
      handleScroll(); // Initialize state on mount
      return () =>
        containerRef.current.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const scrollLeft = () => {
    if (containerRef.current) {
      const scrollAmount = containerRef.current.offsetWidth / 2; // Scroll by half the container width
      containerRef.current.scrollBy({
        left: -scrollAmount,
        behavior: "smooth",
      });
      handleScroll(); // Update arrow states after scrolling
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      const scrollAmount = containerRef.current.offsetWidth / 2; // Scroll by half the container width
      containerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
      handleScroll(); // Update arrow states after scrolling
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        left: 0, // Ensure the first card is visible on load
        behavior: "auto",
      });
    }
  }, []);

  const handleExcelInstructionsClick = () => {
    setExcelInstructions(!excelInstructions);
  };

  const handleSearchTransaction = (value) => {
    setSearchTransaction(value); // Update the searchTransaction state
  };

  const toggleInstitution = (institutionId) => {
    setExpandedTransaction(null);
    setSortOrder("desc"); // Reset Sort by Date to "Newest First"
    setSearchTransaction(""); // Reset search input when switching banks
    setExpandedIBAN(null); // Reset selected IBAN
    setExpandedCurrency(null); // Reset selected currency
    setExpandedInstitution((prev) =>
      prev === institutionId ? null : institutionId
    );
  };

  const toggleIBAN = async (iban, currency) => {
    setSearchTransaction(""); // Reset search input when switching IBANs
    setSortOrder("desc"); // Reset Sort by Date to "Newest First"
    if (expandedIBAN === iban && expandedCurrency === currency) {
      setExpandedIBAN(null);
      setExpandedCurrency(null);
    } else {
      setExpandedIBAN(iban);
      setExpandedCurrency(currency);
      if (!transactions[iban]) {
        setTransactionLoading(true);
        const findAccount = accounts.find((account) => account.iban === iban);
        const accountId = findAccount.id;
        try {
          const response = await fetch(
            `${config.URL_PROD}/api/v1/bank/transactionsAsked/${accountId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (!response.ok) {
            throw new Error(
              `Failed to fetch transactions: ${response.statusText}`
            );
          }
          const result = await response.json();
          setTransactions((prevTransactions) => ({
            ...prevTransactions,
            [iban]: result.transactionsDataWithMerchant,
          }));
        } catch (error) {
          console.error("Error fetching transactions:", error.message);
        } finally {
          setTransactionLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${config.URL_PROD}/api/v1/bank/getAccountsAndBalances`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          if (response.status === 302) {
            const result = await response.json();
            setErrorMessage(result.message);
            setRedirectUrl(result.redirect);
            return;
          }
        }
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.statusText}`);
        }

        const result = await response.json();

        if (
          result.message &&
          result.message?.startsWith("Your requisitions have expired.")
        ) {
          return setErrorMessage(result.message);
        }
        if (result.message?.startsWith("Failed to fetch account")) {
          return setErrorMessage(result.message);
        }

        if (result.message === "No accounts with balances found") {
          setErrorMessage(
            "Sorry but we are working on the issue and will be back soon"
          );
          const timer = setTimeout(() => {
            setErrorMessage(null);
          }, 1500);
          return setAccounts([]);
        }

        setAccounts(result);
        localStorage.setItem("accounts", JSON.stringify(result));
        setAccountsID(result.map((account) => account.id));
        // add accounts id to location
        setIBAN(result.map((account) => account.iban));
        const url = result.map((account) => account.iban);
        setExpandUrls(url);
        setMayGetExtraUrlForEachAccountNumberForExcel(
          result.length > 1 ? true : false
        );

        console.log("Fetched accounts:", result);
        console.log(
          "Accounts ID:",
          result.map((account) => account.id)
        );
        console.log(
          "IBANs:",
          result.map((account) => account.iban)
        );
        console.log("Expand URLs:", url);
        console.log(
          "MayGetExtraUrlForEachAccountNumberForExcel:",
          result.length > 1 ? true : false
        );
      } catch (error) {
        console.error("Error fetching data:", error.message);
        if (error.message === "Failed to fetch data: Unauthorized") {
          navigate("/Login");
        } else {
          setErrorMessage(error.message);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [navigate, setAccountsID]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  if (loading) {
    return <Spinner />;
  }

  if (errorMessage) {
    return (
      <div className={styles.errorMessage}>
        <button
          className={styles.errorCloseBtn}
          onClick={() => setErrorMessage(null)}
        >
          X
        </button>
        <p>{errorMessage}</p>{" "}
        {redirectUrl && (
          <button
            className={styles.reconnectBtn}
            onClick={() => (window.location.href = redirectUrl)}
          >
            Reconnect
          </button>
        )}
      </div>
    );
  }

  const groupedAccounts = accounts?.reduce((acc, account) => {
    if (!acc[account.institution_id]) {
      acc[account.institution_id] = [];
    }
    acc[account.institution_id].push(account);
    return acc;
  }, {});

  const MayGetExtraUrlForEachAccountNumberForExcel =
    localStorage.getItem("getExtraUrlForEachAccountNumberForExcel") === "true";

  const handleAddAccountClick = () => {
    setMessage(
      "You have reached the limit. Contact support to increase the limit."
    );
    setTimeout(() => {
      setMessage(null);
    }, 4000);
  };

  const handlePositiveMessageOnClick = (message) => {
    setMessagePositive(message);
    setTimeout(() => {
      setMessagePositive(null);
    }, 2000);
  };

  const getCurrencySymbol = (currency) => {
    if (!currency) {
      return "€"; // If currency is not available, return as euro
    }

    try {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
      })
        .formatToParts(0)
        .find((part) => part.type === "currency").value;
    } catch (e) {
      return "€"; // If an error occurs, return as euro
    }
  };

  const truncateIBAN = (iban, maxLength = 16) => {
    if (iban.length > maxLength) {
      return iban.substring(0, maxLength) + "**";
    }
    return iban;
  };

  return (
    <div className={styles.main}>
      <div className={styles.navigationWrapper}>
        <button className={styles.scrollButton} onClick={scrollLeft}>
          &#8592;
        </button>
        <div className={styles.bankAccountsContainer} ref={containerRef}>
          {accounts.map((account) => {
            const isExpanded =
              expandedIBAN === account.iban &&
              expandedCurrency ===
                (account?.balanceAmount?.currency || account?.currency);

            return (
              <div
                key={account.id}
                className={`${styles.accountCard} ${
                  isExpanded
                    ? styles.expandedCard
                    : expandedIBAN
                    ? styles.dimmedCard
                    : ""
                }`}
                onClick={() =>
                  toggleIBAN(
                    account.iban,
                    account?.balanceAmount?.currency || account?.currency
                  )
                }
              >
                <div className={styles.accountHeader}>
                  <img
                    className={styles.institutionLogo}
                    src={account.bankLogo || "default-logo.png"} // Use a default logo if none is provided
                    alt={`${account.bankName || "Institution"} Logo`}
                  />
                  <p className={styles.accountIban}>
                    {truncateIBAN(account.iban)}
                  </p>
                  <p className={styles.accountBalance}>
                    <strong>Balance:</strong>{" "}
                    {getCurrencySymbol(
                      account?.currency || account.balanceAmount?.currency
                    ) +
                      +Number(
                        account.balanceAmount?.amount || account?.balanceAmount
                      )}
                  </p>
                </div>
              </div>
            );
          })}

          {/* Add Account Card */}
          <div
            className={`${styles.accountCard} ${
              expandedIBAN ? styles.dimmedCard : ""
            }`}
            onClick={() => navigate("/ConnectToBank")} // Navigate to Add Account page
          >
            <div className={styles.accountHeader}>
              <p className={styles.merchantName}>+ Add Account</p>
            </div>
          </div>
        </div>
        <button className={styles.scrollButton} onClick={scrollRight}>
          &#8594;
        </button>
      </div>
      {expandedIBAN && (
        <div className={styles.transactionsRow}>
          <TransactionTable
            transactions={transactions[expandedIBAN]?.filter(
              (transaction) =>
                transaction.transactionAmount?.currency === expandedCurrency
            )}
            account={accounts.find((account) => account.iban === expandedIBAN)}
            handleSearchTransaction={searchTransaction}
            setSearchTransaction={setSearchTransaction}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            loading={transactionLoading} // Pass transactionLoading as the loading prop
          />
        </div>
      )}
    </div>
  );
};

export default BankAccountsData;
